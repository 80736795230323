export default defineI18nConfig(() => ({
  legacy: false,
  autoRedirectByLocale: false,
  reloadOnLanguageChange: false,
  lazy: true,
  warnHtmlMessage: false,
  numberFormats: {
    'nl-NL': {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
    },
    de: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
    },
    fr: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
    },
  },
}))
